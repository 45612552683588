<template>
  <v-app id="app">
    <v-main>
      <iframe ref="topIframe" src="header.html" frameborder="0" scrolling="no"></iframe>
      <router-view/>
      <iframe ref="bottomIframe" src="footer.html" frameborder="0" scrolling="no"></iframe>
    </v-main>
  </v-app>
</template>
<script>
export default {
  mounted() {
    this.$refs.topIframe.addEventListener('load', this.adjustIframeEight)
    this.$refs.bottomIframe.addEventListener('load', this.adjustIframeEight)
  },
  methods: {
    adjustIframeEight() {
      const iframe = this.$refs.topIframe
      const iframeBottom = this.$refs.bottomIframe
      iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 7 + 'px';
      iframeBottom.style.height = iframeBottom.contentWindow.document.body.scrollHeight + 10 + 'px';
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<template>
  <v-card flat>
    <v-card-text>
      code: {{code}}
    </v-card-text>
    <v-card-text v-if="showQrcode">
      <canvas ref="qrcode"></canvas>
    </v-card-text>
    <v-card-text v-if="showBarcode">
      <canvas ref="barcode"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
// @ is an alias to /src
import Qrcode from 'qrious'
import JsBarcode from 'jsbarcode'


export default {
  name: 'HomeView',
  components: {
    
  },
  data: () => ({
    code: '',
    showQrcode: false,
    showBarcode: false,
    cfg: {
      qrsize: 200,
      barWidth: 2,
      barHeight: 80
    }
  
  }),
  methods: {
    resolveCode(ecode) {
      try {
        if (this.verifyCode(ecode)) {
          const code = atob(ecode)
          const parts = code.split('|')
          switch (parts[0]) {
            case 'q':
              this.showQrcode = true
              this.$nextTick(() => {
                new Qrcode({
                  element: this.$refs.qrcode,
                  value: parts[1],
                  size: this.cfg.qrsize
                })
              })
              
              this.showQrcode = true
              this.code = parts[1]
              break
            case 'b':
              this.showBarcode = true
              this.$nextTick(() => {
                JsBarcode(this.$refs.barcode, parts[2], {
                  format: parts[1], // Puoi scegliere il formato del barcode desiderato
                  displayValue: false, // Mostra il testo insieme al barcode
                  width: this.cfg.barWidth, // Imposta la larghezza delle barre
                  height: this.cfg.barHeight, // Imposta l'altezza del barcode
                })
              })
              
              this.code = parts[2]
              break
          }
        } else {
          this.code = 'invalid code'
        }
      
      } catch (e) {
        this.code = e.toString()
      }
    },
    verifyCode(code) {
      try {
        atob(code)
        return true
      } catch (e) {
        return false
      }
    }
  },
  mounted() {
    this.resolveCode(this.$route.query.c)
    if (window.cfg) {
      this.cfg = window.cfg
    }
    console.log(window.cfg)
  }
}
</script>
